export const MainURL = {
  // HostUrl: 'https://bigdadygames.com:6066',

  //local

  // HostUrl: 'http://97.74.91.204:2585',
  // HostUrl: 'https://newbalaji.com:2585',  // new balaji
  HostUrl: 'http://97.74.91.204:9595',    //demo game


  // HostUrl: 'http://192.168.0.17:9595',
  // 
  // HostUrl: 'http://a5c2a5edf4e8.ngrok.io',
  imageUrl: '/assets'
}
